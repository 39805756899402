/**
 * 変数
*****************************************************/
let news = {}
news = {
  jsonPath: {}, // 取得用
  jsonData: {}, // 格納用
  categorys: {
    'info': 'お知らせ',
    'ir': 'IR',
    'events_seminars': 'イベント・セミナー',
    'events': 'イベント',
    'seminars': 'セミナー',
    'webinars': 'ウェビナー',
    'seminars_webinars': 'セミナー・ウェビナー',
    'products': '製品情報',
    'pr': 'ニュースリリース',
    'applications': 'アプリケーション',
    'support': 'サポート',
  },
  state: {
    pageType: 'news', // どのページなのか
    number: {}, // 表示件数
    paged: {}, // 現在のページNo
    maxPaged: {}, // 最大ページNo
    offset: {}, // ページ送りの際の表示用
  },
  elem: {
    wrap: {}, // Newsエリア
    list: {}, // Newsリスト（出力するエリア）
  },
}

/*****************************************************
 * 処理
*****************************************************/
// 読み込み時実行
document.addEventListener("DOMContentLoaded", function () {
  const breadcrumbItems = document.querySelectorAll('.l-breadcrumb__list__item');

  // パンくずリストが存在しない場合はトップページと判定
  if (breadcrumbItems.length === 0) {
    news.state.pageType = 'top';
  }

  // 2番目のパンくずリスト項目を確認して処理
  if (breadcrumbItems.length > 1) {
    const secondBreadcrumb = breadcrumbItems[1].querySelector('a')?.innerText.trim() || '';
    
    if (secondBreadcrumb === 'ニュース') {
      news.state.pageType = 'news';
    } else {
      news.state.pageType = 'other';
    }
  }

  // 3番目のパンくずリスト項目を確認して処理
  if (breadcrumbItems.length > 2) {
    const thirdBreadcrumb = breadcrumbItems[2].querySelector('a')?.innerText.trim() || '';
    
    // 3番目のパンくずに基づいた追加の条件を設定（例: さらにページタイプを詳細に分けたい場合）
    if (thirdBreadcrumb  === '理科学機器' || thirdBreadcrumb === '電子顕微鏡 総合' || thirdBreadcrumb === '磁気共鳴装置 総合' || thirdBreadcrumb === '質量分析計 (MS) 総合' || thirdBreadcrumb === '半導体製造装置' || thirdBreadcrumb === '産業機器' || thirdBreadcrumb === '医用機器') {
      news.state.pageType = 'news'; // 3番目のパンくずが特定の値の場合の処理
    } else {
      news.state.pageType = 'other';
    }
  }

  news.hash() // ハッシュ（IR用）による処理を実行する関数を呼び出す

  const _news = document.querySelectorAll('.js-news')

  // .js-newsの数分を処理する
  for(let i = 0; i < _news.length; i++) {
    const _cat = _news[i].dataset.category // カテゴリーを変数に設定
    // 該当のカテゴリーの連想配列に諸々を設定
    news.elem.wrap[_cat] = document.querySelectorAll('.js-news')[i]
    news.elem.list[_cat] = document.querySelectorAll('.js-news')[i].querySelector('.js-news-list')
    news.jsonPath[_cat] = _news[i].dataset.jsonPath
    news.state.number[_cat] = Number(_news[i].dataset.number)
    news.state.paged[_cat] = 1
    news.state.offset[_cat] = 0
    news.init(_cat,document.querySelectorAll('.js-news')[i]) // 読込み時、処理する関数を呼び出す
  }
})

/*****************************************************
* 関数
*****************************************************/

/*
 読込み時、処理する関数
*/
news.init = function (_cat,_target) {
	const xhr = new XMLHttpRequest()
	xhr.open("GET", news.jsonPath[_cat]) // 各カテゴリーのjsonを取得
	xhr.send()
	xhr.onreadystatechange = () =>{
		if (xhr.readyState == 4 && xhr.status == 200) {
      // jsonを配列に格納
      news.jsonData[_cat] = []
			news.jsonData[_cat] = JSON.parse(xhr.responseText).data
      // jsonにデータがない場合は諸々要素を非表示にして、それ以降を処理させない
      if(news.jsonData[_cat].length === 0){
        if(news.state.pageType === 'news') {
          _target.querySelector('.js-news-noresult').classList.remove('-hide')
          _target.querySelector('.js-news-list').classList.add('-hide')
          if(_target.querySelector('.js-pagenation')){
            _target.querySelector('.js-pagenation').classList.add('-hide')
          }
        } else {
          _target.remove()
        }
        return
      }
      news.state.maxPaged[_cat] = Math.ceil(news.jsonData[_cat].length / news.state.number[_cat]) // 最大ページ数を設定
      news.generate(_cat) // ニュースリストを生成する関数を呼び出す
      // ページネーションが存在する場合はページネーション関数を実行
      if(!document.querySelector('.js-pagenation')) return
      pagenation.init(_cat)
    // jsonにデータがない場合は諸々要素を非表示、それ以降を処理させない
    } else if (xhr.readyState == 4 && xhr.status == 404) {
      if(news.state.pageType === 'news') {
        _target.querySelector('.js-news-noresult').classList.remove('-hide')
        _target.querySelector('.js-news-list').classList.add('-hide')
        if(_target.querySelector('.js-pagenation')){
          _target.querySelector('.js-pagenation').classList.add('-hide')
        }
      } else {
        _target.remove()
      }
      return
    }
  }
}

/*
  お知らせリストの高さを調整する関数
*/
news.reset = function (_cat) {
  const _h = news.elem.list[_cat].clientHeight
  if(_h <= 0) return
  news.elem.list[_cat].style.height = _h + 'px'
}

/*
  ニュースリストを生成する関数
*/
news.generate = function (_cat) {
  news.reset(_cat) // お知らせリストの高さを調整する関数を呼び出す
  // 対象のリストをアニメーションで非表示にさせる
  gsap.to(news.elem.list[_cat], {
    opacity: 0,
    duration: 0.1,
    onComplete: () => { 
      news.elem.list[_cat].innerHTML = '' // 対象のリストの中身を空にする
      // 表示開始位置（offset）からjsonデータ分をループを回す
      for (let i = news.state.offset[_cat]; i < news.jsonData[_cat].length; i++) {
        if(i >= (news.state.number[_cat] * news.state.paged[_cat]) || !news.jsonData[_cat][i]) break // 表示件数以上になったらループを止める
        // HTMLを成形（ページによってタグが違うためnews,topとその他で分岐）
        if(news.state.pageType === 'news' || news.state.pageType === 'top') {
          news.elem.list[_cat].appendChild(news.listHtml(news.jsonData[_cat][i],'news'))
        } else {
          news.elem.list[_cat].appendChild(news.listHtml(news.jsonData[_cat][i],'other'))
        }
      }
      news.elem.list[_cat].style.height = ''
      gsap.to(news.elem.list[_cat], {
        opacity: 1,
        duration: 0.2
      })
     },
  })
}

/*
 HTMLを成形して返却
*/
news.listHtml = function (_data,_type) {
  const _li = document.createElement("li")

  let _class = 'link'
  let _catText = ''

  // ページによってタグを分ける
  if(_type === 'news') {
    _li.classList.add('m-newslist__item')
    _catText = news.categorys[_data.news_cat_label]
    _catText = '<p class="tag tag--'+_data.news_cat_label+'">'+_catText+'</p>'
    _class = 'm-newslist__item__link'
  } else {
    _li.classList.add('m-news__item')
  }

  // リンクを別タブ（_blank）にするかどうか
  const _target = (_data.link_target === 'false' || _data.link_target === 'FALSE' || _data.link_target === 'internal')? '' : ' target="_blank"'
  let _linkType = ''
  let _fileSize = _data.filesize === ''? '':'<span class="txt--sub"> ('+_data.filesize+') </span>' // ファイルサイズ

  // リンクのアイコンを分ける
  if(_data.link_target === 'outer') {
    _linkType = ' icon__newwindow'
  } else if(_data.link_target === 'pdf') {
    _linkType = ' icon__pdf'
  } else if(_data.link_target === 'word') {
    _linkType = ' icon__doc'
  } else if(_data.link_target === 'excel') {
    _linkType = ' icon__xls'
  } else if(_data.link_target === 'zip') {
    _linkType = ' icon__zip'
  }

  // 諸々の値を要素に設置する
	const _inner = '\
      <p class="date">' +_data.date.replace(/年|月/g, '/').replace(/日/g, '')+ '</p>\
      ' +_catText+ '\
      <p class="txt"><span class="txt__link' +_linkType+ '">' +_data.title+_fileSize+ '</span></p>\
      <a class="' +_class+ '" href="' +_data.link_url+ '"'+_target+'></a>\
  </li>'
  _li.innerHTML = _inner
  return _li // 返却
}

/*
 ハッシュ（IR用）による処理を実行する関数
*/
news.hash = function () {
  let _hash = location.hash
  if(_hash && news.state.pageType === 'news'){
    _hash = _hash.replace("#", "")
    document.querySelector('[data-hash="'+_hash+'"]').click()
  }
}
